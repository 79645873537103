import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { NavBar, Svg, Icon, IconButton, Dropdown } from '../';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  ...props
}): ReactNode {
  const { t } = useTranslation('Header');

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  return (
    <div>
      <NavBar
        className="justify-between border-b-4 border-black"
        isSticky={isSticky}
      >
        <div className="flex justify-between flex-1">
          <div
            className="flex items-center"
            id="historyArea"
            style={{ color: '#fff' }}
          ></div>
          <div className="flex items-center" style={{}}>
            <a
              href="#"
              target="_blank"
              id="addReportBtn"
              className="leading-none font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out focus:outline-none text-primary-light hover:bg-primary-light hover:text-black focus:text-black focus:bg-primary-light active:opacity-80 bg-primary-main inline-flex border outline-none border border-r-0 last:border-r border border-secondary-light first:rounded-l-md last:rounded-r-md text-white text-base p-2 min-w-18"
            >
              Add Report
            </a>
          </div>
        </div>
      </NavBar>
      <NavBar
        className="justify-between border-b-4 border-black"
        isSticky={isSticky}
      >
        <div className="flex justify-between flex-1">
          <div className="flex items-center">
            {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
            <div
              className={classNames(
                'inline-flex items-center mr-3',
                isReturnEnabled && 'cursor-pointer'
              )}
              onClick={onClickReturn}
            >
              {/* {isReturnEnabled && (
              <Icon name="chevron-left" className="w-8 text-primary-active" />
            )} */}
              <div className="ml-4">
                <img
                  alt="Logo"
                  style={{ height: '40px' }}
                  src="assets//gratirad-logo.png"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center">{children}</div>
          <div className="flex items-center">
            {/* <span className="mr-3 text-lg text-common-light">
            {t('INVESTIGATIONAL USE ONLY')}
          </span> */}
            <Dropdown id="options" showDropdownIcon={false} list={menuOptions}>
              <IconButton
                id={'options-settings-icon'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
              >
                <Icon name="settings" />
              </IconButton>
              <IconButton
                id={'options-chevron-down-icon'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active"
              >
                <Icon name="chevron-down" />
              </IconButton>
            </Dropdown>
          </div>
        </div>
      </NavBar>
    </div>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
